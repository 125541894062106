@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

/* Work Section */
.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        whitesmoke,
        lightgrey  
    )!important;
}
/* End of Work Section */


/* About Me Section */


@media (max-width: 639px){

    

    .about_component{
        height: 120vh;
    }

    .about_outer{
        display: flex;
        flex-direction: column;
      
    }

    .about_me_text{
        font-size: x-large;
        
    }

    .aaman__selfie--container{
        display: none;
    }

    .aaman__selfie{
        display: none;
    }

    .skills_component{
        height: 100vh;
    }

    .about__me--text{
        max-height: 50vh;
        margin-top: 20%;
    }



   
}

.about_component{
    display: flex;
    flex-direction: column;
    padding: 10%;
}

.about__me--container{
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 1000px;
    margin-top: 10%;
    
    
}

.about_me_text{
    align-self: center;
}

.aaman__selfie--container{
    height: 75%;
    margin-bottom: 4%;
    display: none;
    
}

.aaman__selfie{
    height: 100%;
    display: none;
}

.work_component{
    padding: 10%;
    height: 100vh;
/*  */

}


/* Navbar Section */


.nav-btn:hover{
    color: rgb(14 165 233);
    text-decoration: underline;
}

.work-card{
    border: 1px solid lightgrey;
}



.about__me--text{
    margin-left: 10px;
}

.card__icon{
    margin-top: 20px;
}

.card{
    display: flex;
    flex-direction: column;
}


.card:hover{
    animation: glow 0.75s infinite alternate;
    animation-delay: 0.1s;
}





@keyframes glow {
    0%{
        box-shadow: 0 0 10px rgb(0, 194, 224);
    }

    100%{
        box-shadow: 0 0 25px rgb(0, 194, 224);
    }
}

@media (min-width: 1000px){
    .about__me--container{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        font-size: large;
        height: 400px;
        width: 1000px;
        padding: 1%;
        margin-top: 0;
     
        
    }


    .aaman__selfie--container{
        height: 90%;
        display: block;
        
    }

    .aaman__selfie{
        display: inline;
    }


    .about__me--text{
     
        height: 95%;
        padding: 20px;
        width: 50%;
    }


}
